import React from "react"
import { Link } from "gatsby"
import "./aboutUs.css"

const AboutUs = () => {
  return (
    <div className="about-us">
      <h3 className="text-primary">About Us</h3>
      <p>
        Compare n Save is a home loan comparison service brought to you by TAG
        Finance and Loans Pty Ltd –- leading Australian home loan brokers.
      </p>
      <h6 className="text-primary">The service is simple</h6>
      <p>
        We help you to compare 100s of loans to potentially save $1000s and when
        you’re ready to proceed, we’ll manage the entire application process,
        saving you time.
      </p>
      <h6 className="text-primary">Negotiated rates</h6>
      <p>
        Looking for a lower rate than advertised?{" "}
        <Link to="/contact">Contact us</Link> about Negotiated Rates. Depending
        on your circumstances we may be able to negotiate a lower rate for you.
      </p>
      <h6 className="text-primary">Trusted Brands</h6>
      <p>
        We only compare and arrange home loans with Australia’s leading banks
        and other lenders.
      </p>
      <h6 className="text-primary">Leverage our expertise at no cost</h6>
      <p>
        Our home loan service is provided to you at no cost, as we are
        remunerated by lenders on the successful settlement and retention of
        your loan.
      </p>
      <h6 className="text-primary">Our interests are aligned</h6>
      <p>
        As we are only remunerated by lenders on the successful settlement and
        retention of your loan, it is in our best interest to ensure we help you
        select the right loan to meet your objectives, now and into the future.
      </p>
    </div>
  )
}

export default AboutUs
